import { useState } from "react"

import Icon from "../../Icon/Icon";

import styles from "./MultiField.module.css";

export default function MultiField({ values, handleAdd, handleRemove }) {
    const [value, setValue] = useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
    }

    const handleAddValue = () => {
        handleAdd(value);
        setValue("");
    }

    let valueElements = values.map((v,i) => (<li key={i}>{v}<Icon icon="delete" colour="#f00" onClick={() => handleRemove(v)}/></li>));

    return (
        <div>
            <div className={styles.multiField}>
                <input className={styles.field} value={value} onChange={handleChange} />
                <div className={styles.add} onClick={handleAddValue}><Icon icon="add"/></div>
            </div>
            <br />
            <ul className={styles.values}>{valueElements}</ul>
        </div>
    )
}