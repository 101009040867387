import React from "react";
import { AuthContext } from "../../Auth/Auth";

import styles from "./LoginPage.module.css";

export default function LoginPage() {
    const auth = React.useContext(AuthContext);

    return (
        <div className={styles.borderBox}>
            <div className={styles.whiteBox}>
                <div className={styles.circleLogo} />
                <div className={styles.googleLogin} onClick={auth.login} />
            </div>
        </div>
    )
}
