import styles from "./Table.module.css";
import Icon from "../../../Icon/Icon";
import { Link } from "react-router-dom";

export default function Table({rowData}) {
    if (rowData) {
        let rows = rowData.map(row => {
            let status;

            if (row.failedAt && row.failedAt >= row.lastRunAt) status = "Failed";
            else if (row.lockedAt) status = "Running";
            else status = "Waiting";

            return (
                <tr key={`table-row-${row._id}`}>
                    <td>{row.data.name}</td>
                    <td>{row.name}</td>
                    <td>{row.repeatInterval}</td>
                    <td className={styles[status]}>{status}</td>
                    <td className={styles.actions}><Link to={"/edit/" + row._id}><Icon icon="edit" colour="#fff"/></Link></td>
                </tr>
            );
        });

        return (
            <table className={styles.table}>
                <colgroup>
                    <col span="1" style={{width: "35%"}}/>
                    <col span="1" style={{width: "20%"}}/>
                    <col span="1" style={{width: "20%"}}/>
                    <col span="1" style={{width: "20%"}}/>
                    <col span="1" style={{width: "5%", minWidth: "75px"}}/>
                </colgroup>
                <tbody>
                    <tr key={"table-header"}>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Interval</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    {rows}
                </tbody>
            </table>
        );
    }
    
    return <h1>Failed</h1>
}