import { useEffect, useState } from "react";
import request from "superagent";

import Cookies from "../../../helpers/Cookies";
import Config from "../../../helpers/Config";

import DynamicForm from "../../Form/DynamicForm";
import Status from "../../Form/Status/Status";
import Page from "../Page";


export default function CreatePage() {
  const [templates, setTemplates] = useState([]);
  const [status, setStatus] = useState({type: "status"});

  useEffect(() => {
    // get templates on load
    async function getData() {
      try {
        let res = await request
          .get(`${Config.API_URL}/templates/all`)
          .set("Authorization", `Bearer ${Cookies.getCookie("token")}`);
        return setTemplates(res.body);
      } catch (err) {
        return setStatus({type: "error", message: err.response.body.error});
      }
    }

    getData();
  }, []);

  // set determining field for form layout
  let selector = {
    name: "Template",
    type: "Dropdown",
    options: templates,
    required: true
  }

  return (
    <Page>
      <h1>Create Dashboard</h1>
      <DynamicForm actionUrl="/dashboards/create/" selector={selector} selectionUrl="/templates/" />
      <Status type={status.type} message={status.message} />
    </Page>
  )
}