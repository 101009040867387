import React from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header'
import Icon from '../Icon/Icon';

import styles from "./Page.module.css";

export default function Page({logo, children}) {
  const navigate = useNavigate();

  const handleClick = () => navigate(-1);

  return (
    <>
        <Header />
        <div className={styles.backBtn} onClick={handleClick}><Icon icon="arrow_back"/></div>
        {logo ? <div className={styles.logo}></div> : null}
        <div className={styles.page}>
          {children}
        </div>
    </>
  )
}