import { useState } from "react"

import styles from "./Field.module.css";

export default function Field({ handleUpdate }) {
    const [value, setValue] = useState("");

    const handleChange = (event) => {
        handleUpdate(event.target.value);
        setValue(event.target.value);
    }

    return (
        <input className={styles.field} value={value} onChange={handleChange} />
    )
}
