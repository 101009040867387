import { useState } from "react";

import Icon from "../../Icon/Icon";

// values: [{value: "value", label: "label", tooltip: "tooltip", checked: true}]
export default function MultiCheck({ values, handleUpdate }) {
    const [checkedValues, setCheckedValues] = useState(values);

    const handleCheck = (index) => {
        const newValues = [...checkedValues];
        newValues[index].checked = !newValues[index].checked;
        setCheckedValues(newValues);
        handleUpdate(newValues.filter(value => value.checked).map(value => value.value));
    }

    return (
        <div>
            {checkedValues.map((value, index) => (
                <div key={index}>
                    <input type="checkbox" checked={value.checked} onChange={() => handleCheck(index)} />
                    <label>{value.label}<Icon icon="info" tooltip={value.tooltip}/></label>
                </div>
            ))}
        </div>
    )
}