import React from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../Auth/Auth'

import styles from "./Header.module.css";

export default function Header() {
    const auth = React.useContext(AuthContext);

    return (
        <div>
            <ul className={styles.header}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/login">{auth.user || "Login"}</Link></li>
                <li>{ auth.user ? <Link to="/login" onClick={auth.logout}>Logout</Link> : null }</li>
            </ul>
        </div>
    )
}
