import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import request from "superagent";

import Cookies from "../../../helpers/Cookies";
import Config from "../../../helpers/Config";

import Page from "../Page";
import Button from "../../Form/Button/Button";
import Status from "../../Form/Status/Status";

import styles from "./EditPage.module.css";

export default function EditPage() {

    const [data, setData] = useState({});
    const [status, setStatus] = useState({type: "status"});
    const { id } = useParams();

    useEffect(() => {
        async function getData() {
            let dash = await request
                .get(`${Config.API_URL}/dashboards/${id}`)
                .set("Authorization", `Bearer ${Cookies.getCookie("token")}`);

            setData(JSON.stringify(dash.body, null, 2));
        }

        getData();
    }, [id]);


    const handleUpdate = (e) => {
        setData(e.target.value);
    }

    const handleSubmit = async () => {
        setStatus({type: "status"});

        let jsonData;
        try {
            jsonData = JSON.parse(data);
        } catch (err) {
            return setStatus({type: "error", message: err.message});
        }

        try {
            await request
                .post(`${Config.API_URL}/dashboards/update/${id}`)
                .set("Authorization", `Bearer ${Cookies.getCookie("token")}`)
                .send(jsonData);
            
            return setStatus({type: "success", message: "Done!"});
        } catch (err) {
            return setStatus({type: "error", message: err.response.body.error});
        }
    }

    // Matt Main smells

    return (
        <Page>
            <h1>Edit Dashboard</h1>
            <textarea className={styles.jobBox} value={data} onChange={handleUpdate} />
            <br />
            <Button onClick={handleSubmit}>Update</Button>
            <Status type={status.type} message={status.message} />
        </Page>
    )
}