import { Link } from 'react-router-dom';
import Button from "../../Form/Button/Button";
import Page from '../Page';

export default function HomePage() {
  return (
    <Page logo={true}>
      <h2>Dashboard Provisioning</h2>
      <div>
        <Link to="/create"><Button>Create Dashboard</Button></Link>
        <Link to="/update"><Button>Live Dashboards</Button></Link>
      </div>
    </Page>
  )
}