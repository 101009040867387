import { useState } from 'react';
import styles from "./Dropdown.module.css";

export default function Dropdown({options, onSelect}) {
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    // actions
    const toggleOptions = () => {
        setOpen(!open);
    }

    const select = (id) => {
        setSelectedId(id);
        toggleOptions();
        onSelect(id);
    }

    // rendering
    let ops = [];
    // options
    if (options) {
        for (let i = 0; i < options.length; i++) {
            ops.push(<li key={options[i].id} onClick={() => select(options[i].id)}>{options[i].value}</li>);
        }
    }

    let value;
    if (selectedId === -1) value = "Select option...";
    else value = options.find(({id}) => id === selectedId).value;

    return (
        <div className={styles.dropdown}>
            <p onClick={toggleOptions}>{value} <span>&#129171;</span></p>
            { open ? 
                <ul>
                    {ops}
                </ul>
            : <></>}
        </div>
    )
}
