import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./UpdatePage.module.css";

import request from "superagent";

import Cookies from "../../../helpers/Cookies";
import Config from "../../../helpers/Config";

import Table from "./Table/Table";
import Page from "../Page";
import Search from "./Searchbar/Searchbar";
import Dropdown from "../../Form/Dropdown/Dropdown";
import Status from "../../Form/Status/Status";

export default function UpdatePage() {
  const [dashboards, setDashboards] = useState([]);
  const [status, setStatus] = useState({type: "status"});
  const [type, setType] = useState(-1);
  const { search } = useParams();

  useEffect(() => {
    async function getData() {
      try {
        let res = await request
          .get(`${Config.API_URL}/dashboards/all`)
          .set("Authorization", `Bearer ${Cookies.getCookie("token")}`);
        
        return setDashboards(res.body);
      } catch (err) {
        return setStatus({type: "error", message: err.response.body.error});
      }
    }

    getData();
  }, []);

  const selectType = id => setType(id);

  let results;

  if (search) results = dashboards.filter(dash => dash.data.name.toLowerCase().includes(search.toLowerCase()));
  else results = dashboards;

  results.sort((a,b) => {
    let n1 = a.data.name.toLowerCase();
    let n2 = b.data.name.toLowerCase();

    if (n1 < n2) return -1;
    if (n1 > n2) return 1;
    return 0;
  });

  let types = [...new Set(dashboards.map(dash => dash.name))];
  types = types.filter(t => t !== undefined && t !== null);
  let options = [{id: 0, value: "All"}, ...types.map((t, i) => {return {id: i+1, value: t}})];

  if (type > 0) results = results.filter(r => r.name === options[type].value);
  
  return (
    <Page>     
      <h1>Dashboards</h1>

      <Status type={status.type} message={status.message} />

      <div className={styles.filterBar}>
        <Search route="update"/>
        <Dropdown options={options} onSelect={selectType}/>
      </div>
      
      <Table rowData={results}/>
    </Page>
  )
}
