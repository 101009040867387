import { useEffect, useState } from "react";

import styles from "./Form.module.css";
import request from "superagent";

import Cookies from "../../helpers/Cookies";
import Config from "../../helpers/Config";

import Select from "react-select";

import Button from "./Button/Button";
import Label from "./Label/Label";
import Field from "./Field/Field";
import MultiField from "./MultiField/MultiField";
import MultiCheck from "./MultiCheck/MultiCheck";
import Status from "./Status/Status";

export default function DynamicForm({ actionUrl, selector, selectionUrl }) {
    const [formData, setFormData] = useState({});
    const [fields, setFields] = useState([]);
    const [selectorId, setSelectorId] = useState(-1);
    const [status, setStatus] = useState({type: "status"});
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        // get companies on load
        async function getData() {
            const response = await fetch('https://accounts.circlecloud.tech/api/customers', {
                method: 'GET',
                headers: {
                    "x-app-name": "Circle-Dashboards",
                    "x-api-key": "26PsTdu/YORXbBJ/5vkNVDVtzBEZ9DGRCs5wFfpuqmy8zuN2kY1mUSL8RuVxqkpySDxYil0zAPvnxRcXs0615A==",
                }
            });

            let data = await response.json();

            if (!data.success) throw data.error;

            setCompanies(data.data);
        }

        getData();
    }, []);

    /////////////
    // actions //
    /////////////
    const handleSelection = async (newValue, actionMeta) => {
        let id = newValue.value;
        // get template and reset form data
        const apiRes = await request
            .get(Config.API_URL + selectionUrl + id)
            .set("Authorization", `Bearer ${Cookies.getCookie("token")}`);
        setFields(apiRes.body);
        let prefilled_fields = apiRes.body.filter(field => field.type === "multi-check");
        let new_form_data = {};
        for (let i = 0; i < prefilled_fields.length; i++) {
            new_form_data[prefilled_fields[i].placeholder] = prefilled_fields[i].options.filter(option => option.checked).map(option => option.value);
        }
        setFormData(new_form_data);
        setSelectorId(id);
    }

    const handleUpdate = (name, value) => {
        // update form data
        console.log(formData);
        let newFormData = {...formData};
        newFormData[name] = value;
        setFormData(newFormData);
    }

    const handleAdd = (name, value) => {
        // add new value to array in form data
        let newFormData = {...formData};
        if (newFormData[name]) newFormData[name] = [...newFormData[name], value];
        else newFormData[name] = [value];
        setFormData(newFormData);
    }

    const handleRemove = (name, value) => {
        // remove value from array in form data
        let newFormData = {...formData};
        newFormData[name] = formData[name].filter(v => v !== value);
        if (newFormData[name].length === 0) {
            delete newFormData[name];
        }
        setFormData(newFormData);
    }

    const handleSubmit = async () => {
        setStatus({type: "working"});

        // submit form data
        const res = await fetch(Config.API_URL + actionUrl + selectorId, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${Cookies.getCookie("token")}`,
                "Content-Type": "application/json",
                "Accept": "text/event-stream"
            },
            body: JSON.stringify(formData)
        });

        function readChunks(reader) {
            return {
                async *[Symbol.asyncIterator]() {
                    let readResult = await reader.read();
                    while (!readResult.done) {
                        yield readResult.value;
                        readResult = await reader.read();
                    }
                }
            }
        }

        const reader = res.body.getReader();
        for await (const chunk of readChunks(reader)) {
            let data = "";

            for (let i = 0; i < chunk.length; i++) {
                data += String.fromCharCode(chunk[i]);
            }

            let datum = data
                .split("\n\n")
                .filter(d => d !== "")
                .map(d => {
                    let result = JSON.parse(d.trim());
                    console.log(result);
                    return result;
                });
                
            setStatus(datum[datum.length-1]);

            if (datum[datum.length-1].type === "success" && datum[datum.length-1].message.startsWith("https://")) {
                window.open(datum[datum.length-1].message, "_blank");
            }
        }
    }

    ////////////
    // render //
    ////////////
    let fieldElems = [];
    for (let i = 0; i < fields.length; i++) {
        let label = <Label key={`label-${fields[i].placeholder}`} required={fields[i].required}>{fields[i].name}</Label>;

        let key = "value-" + fields[i].placeholder;

        // add field
        let field = null;
        switch(fields[i].type) {
            case "dropdown":
                let options = fields[i].options;
                if (fields[i].placeholder === "company") options = companies.map(c => ({value: c.id, label: c.name}));
                field = <Select key={key} className={styles.select} options={options} onChange={(newValue, actionMeta) => handleUpdate(fields[i].placeholder, newValue.value)} />;
                break;
            case "text":
                field = <Field key={key} handleUpdate={(value) => handleUpdate(fields[i].placeholder, value)} />;
                break;
            case "multi-text":
                field = <MultiField key={key} values={formData[fields[i].placeholder] || []} handleAdd={(v) => handleAdd(fields[i].placeholder, v)} handleRemove={(v) => handleRemove(fields[i].placeholder, v)} />;
                break;
            case "multi-check":
                field = <MultiCheck key={key} values={fields[i].options} handleUpdate={(values) => handleUpdate(fields[i].placeholder, values)} />;
                break;
            default:
                field = <p key={fields[i].placeholder}>{fields[i].placeholder}</p>;
                break;
        }

        if (fields[i].type === "multi-text") fieldElems.push(<div key={`field-${fields[i].placeholder}`} className={styles.multiField}>{label}{field}</div>);
        else fieldElems.push(<div key={`field-${fields[i].placeholder}`} className={styles.field}>{label}{field}</div>)
    }

    return (
        <div key="form" className={styles.form}>
            <div key="dashboardSelector" className={styles.field}>
                <Label key="label-selector">{selector.name}</Label>
                <Select key="value-selector" className={styles.select} options={selector.options} onChange={handleSelection} />
            </div>
            {fieldElems}
            <p></p>
            <Button key="submit" onClick={handleSubmit}>Submit</Button>
            <p></p>
            <Status key="status" type={status.type} message={status.message} />
        </div>
    )
}