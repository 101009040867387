import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../Icon/Icon";

import styles from "./Searchbar.module.css";

export default function Search({route}) {
    const [value, setValue] = useState("");
    const navigate = useNavigate();

    const handleChange = (e) => {
        navigate(`/${route}/${e.target.value}`, {replace: true}, [navigate]);
        setValue(e.target.value);
    }

    return (
        <div className={styles.searchbar}>
            <input placeholder="Search..." value={value} onChange={handleChange}/>
            <Icon icon="search" colour="#aaa"/>
        </div>
    )
}