import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AntiAuth, AuthProvider, RequireAuth } from "./components/Auth/Auth";

import HomePage from './components/Pages/HomePage/HomePage';
import CreatePage from './components/Pages/CreatePage/CreatePage';
import ErrorPage from './components/Pages/ErrorPage/ErrorPage';
import LoginPage from './components/Pages/LoginPage/LoginPage';
import UpdatePage from "./components/Pages/UpdatePage/UpdatePage";
import EditPage from "./components/Pages/EditPage/EditPage";


const router = createBrowserRouter([
  {
    path: "login",
    element: <AntiAuth><LoginPage /></AntiAuth>
  },
  {
    path: "/",
    element: <RequireAuth><HomePage /></RequireAuth>, 
    errorElement: <ErrorPage />
  },
  {
    path: "create",
    element: <RequireAuth><CreatePage /></RequireAuth>,
    errorElement: <ErrorPage />
  },
  {
    path: "update",
    element: <RequireAuth><UpdatePage /></RequireAuth>,
    errorElement: <ErrorPage />
  },
  {
    path: "update/:search",
    element: <RequireAuth><UpdatePage /></RequireAuth>,
    errorElement: <ErrorPage />
  },
  {
    path: "edit/:id",
    element: <RequireAuth><EditPage /></RequireAuth>,
    errorElement: <ErrorPage />
  }
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;